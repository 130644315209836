import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import Seo from "src/components/SEO"
import { PrimaryTitle } from "src/components/Typography"
import BaseContainer from "src/components/Base/BaseContainer"
import BoxList from "src/components/BoxList"

const subpages = [
  {
    title: "Achievements",
    subtitle: "Learn about our progress to date and main achievements",
    path: "/work/achievements",
    icon: "feather-award",
  },
  {
    title: "Room for more funding",
    subtitle:
      "Learn how our programs will be influenced by additional donations",
    path: "/work/room-for-more-funding",
    icon: "feather-battery-charging",
  },
  {
    title: "Our main outcomes",
    subtitle: "Read about our main direct and indirect outcomes",
    path: "/work/outcomes",
    icon: "feather-list",
  },
]

const WorkPage = () => {
  const [observed, setObserved] = useState(false)

  useEffect(() => {
    setObserved(true)
  }, [])

  return (
    <>
      <Seo
        title="Our work"
        description="What have we already achieved? What could we achieve with additional financial support? See what we’re working on to change the fate of farm animals"
      />
      <PageHeader className={observed ? "-observed" : ""}>
        <PrimaryTitle as="h1">Our work</PrimaryTitle>
      </PageHeader>
      <BaseContainer>
        <BoxList items={subpages} />
      </BaseContainer>
    </>
  )
}

const PageHeader = styled.header`
  padding: 180px 0 50px;
  text-align: center;
  transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};

  &:not(.-observed) {
    opacity: 0;
    transform: translateY(20px);
  }

  ${media.tablet} {
    padding: 250px 0 100px;
  }
`

export default WorkPage
